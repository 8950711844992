import React from "react";
import { Link } from "react-router-dom";
import { Icon, HRule, Box, HStack, VStack } from "../components";
import Icons from "./Icons";

const NavItem = ({ icon, label, route, collapsed }) => {
  return (
    <>
      <Link to={route}>
        <HStack
          css={{
            width: `calc(100% - $2)`,
            p: "$2",
            cursor: "pointer",
            fontSize: "14px",
            color: "$ppForestContrast",
            textAlign: "center",
            alignItems: "center",
            "&:hover": {
              color: "$neutral0",
            },
          }}
        >
          {icon && (
            <Box css={{ ml: "$2", mr: "$2" }}>
              <Icon
                icon={icon}
                css={{ fontSize: "30px", display: "inline-block" }}
              />
            </Box>
          )}
          <Box
            css={{
              fontSize: "16px",
              display: collapsed ? "none" : "block",
              transition: collapsed
                ? `opacity 3000ms, display 30ms`
                : `opacity 5000ms`,
              textWrap: "nowrap",
              opacity: collapsed ? 0.5 : 1,
            }}
          >
            {label}
          </Box>
          <HRule />
        </HStack>
      </Link>
    </>
  );
};

const Nav = ({ collapsed, items }) => {
  return (
    <VStack css={{ mt: "40px", gap: "$3" }}>
      {items.map(({ icon, label, route }, i) => (
        <React.Fragment key={i}>
          <NavItem
            collapsed={collapsed}
            icon={icon}
            label={label}
            route={route}
          />
        </React.Fragment>
      ))}
    </VStack>
  );
};
export default Nav;
