const getAuthConfig = () => {
  if (
    window.location.host.includes("localhost") ||
    window.location.host === "stagevoice.prescient.properties" ||
    window.location.host === "stageadmin.prescient.properties"
  ) {
    return {
      //Dev
      BaseUrl: "https://stageauth.prescient.properties/",
      ClientId: "7j0uh5pj3rp0mfj6d1qhv5ro8u",
      //Prod
      // BaseUrl: "https://auth.prescient.properties/",
      // ClientId: "3quifjha3hvc3557eor4msmjv9",
    };
  }

  return {
    //Prod
    BaseUrl: "https://auth.prescient.properties/",
    ClientId: "3quifjha3hvc3557eor4msmjv9",
  };
};

const createUrl = (path: string, suffix) => {
  const config = getAuthConfig();
  return `${config.BaseUrl}${path}?client_id=${config.ClientId}${suffix}`;
};

const auth = {
  getLoginUrl: () => {
    return createUrl(
      "login",
      "&response_type=code&redirect_uri=" + window.location.origin
    );
  },
  getLogoutUrl: () => {
    return createUrl(
      "logout",
      "&logout_uri=" + window.location.origin + "/login"
    );
  },
};

export default auth;
